.esri-basemap-gallery__item-thumbnail {
  height: 24px !important;
  width: 24px !important;
}

.esri-basemap-gallery__item-title {
  display: none;
}

.esri-basemap-gallery {
  width: 44px !important;
  min-width: unset !important;
  margin-bottom: 16px;
}

.esri-basemap-gallery__item {
  min-width: 40px;
  padding: 5px !important;
}

.esri-basemap-gallery__item-container {
  display: block;
  width: 44px;
  height: 44px;
  overflow: hidden;
}

.esri-basemap-gallery__item-container:hover {
  height: 240px;
}

.esri-ui-bottom-right {
  flex-direction: column !important;

  justify-content: space-between !important;
}
