body {
  font-family: 'Roboto', sans-serif;
  margin: 0px;
}

::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: .6em;
  height: .6em;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: rgb(128, 128, 128);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
